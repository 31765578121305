import PropTypes from 'prop-types';
import { createContext } from 'react';
import { useMemoryState } from '../utils/memoryState';

// ----------------------------------------------------------------------

const defaultFilter = {
    f5: 0,
    page: 1,
    count: 12,
    sort: 0,
    sort_type: 3,
    item_type: [0],
    qty:[true, true],
    totalRow: 0,
    forSale: 1,
}

const initialState = {
    ...defaultFilter,
    onf5:()=>{},
    onChangePage: () => { },
    onChangeCount: () => { },
    onChangeSort: () => { },
    onChangeSortType: () => { },
    onChangeItemType: () => { },
    onChangeItemQty: () => { },
    onChangeSale: () => { },
    onChangeTotalRow: () => { },
    onReset: () => {}
};

const FilterItemContext = createContext(initialState);

// ----------------------------------------------------------------------

FilterItemProvider.propTypes = {
  children: PropTypes.node,
};

function FilterItemProvider({ children }) {

    const [filterSetting, setFilterSetting] = useMemoryState('filterItemSetting', {
        f5: initialState.f5,
        page: initialState.page,
        count: initialState.count,
        sort: initialState.sort,
        sort_type: initialState.sort_type,
        item_type: initialState.box_type,
        qty: initialState.qty,
        totalRow: initialState.totalRow,
        forSale: initialState.forSale,
    });

    const onReset = () => {
        setFilterSetting({
            ...filterSetting,
            f5: initialState.f5,
            // page: initialState.page,
            count: initialState.count,
            sort: initialState.sort,
            // sort_type: initialState.sort_type,
            item_type: initialState.item_type,
            qty: initialState.qty,
            forSale: initialState.forSale,
        });
    };

    const onf5 = (state) => {
        setFilterSetting({
            ...filterSetting,
            f5:state,
        });
    };

    const onChangeCount = (state) => {
        setFilterSetting({
            ...filterSetting,
            count: state,
        });
    };
    
    const onChangePage = (state) => {
        setFilterSetting({
            ...filterSetting,
            page: state,
        });
    }
        
    const onChangeSort = (state) => {
        setFilterSetting({
            ...filterSetting,
            sort: state,
            
        });
    };
    
    const onChangeSortType = (state) => {
        setFilterSetting({
            ...filterSetting,
            sort_type: state,
        });
    };

    const onChangeItemType = (state) => {
        setFilterSetting({
            ...filterSetting,
            item_type: state,
            page:1
        });
    };

    const onChangeItemQty = (state) => {
        setFilterSetting({
            ...filterSetting,
            qty: state,
            page:1
        });
    };

    const onChangeSale = (state) => {
        setFilterSetting({
            ...filterSetting,
            forSale: state,
            page:1
        });
    };

    const onChangeTotalRow = (state) => {
        setFilterSetting({
            ...filterSetting,
            totalRow: state,
        });
    };

    return (
        <FilterItemContext.Provider
            value={{
                ...filterSetting,
                onf5,
                onChangePage,
                onChangeCount,
                onChangeSort,
                onChangeSortType,
                onChangeItemType,
                onChangeItemQty,
                onChangeSale,
                onChangeTotalRow,
                onReset
            }}
        >
            {children}
        </FilterItemContext.Provider>
    );
}

export { FilterItemProvider, FilterItemContext }
