import SvgIconStyle from '../../../components/SvgIconStyle';
import { AvataAddr } from '../../../utils/cryptoWallet';
import { TEST_NET } from '../../../utils/constants';
import { release } from '../../../featureConfig';

// ----------------------------------------------------------------------
const getIcon = (name) => <img alt="i" src={`/icons/${name}`} sx={{ width: 1, height: 1 }} loading="lazy" />;

const ICONS = {
  bricks: getIcon('brick-token.png'),
  boxs: getIcon('box-con.png'),
  heroes: getIcon('heroes-icon.png'),
  items: getIcon('items-icon.png'),
  houses: getIcon('house-icon.png'),
  hotels: getIcon('hotel-icon.png'),
  events: getIcon('events-icon.svg'),
  faucet: getIcon('faucet-icon.png'),
  spinner: getIcon('minigames-icon.svg'),
  rankMTB: getIcon('rank-icon'),
  account: <AvataAddr/>
};

const slideAccount = {
  items: [
    {
      title: 'Account',
      path: '/account/inventory',
      icon: ICONS.account,
      children: [
        (!release.account.claim) ? undefined : { title: 'Claim', path: '/account/claim' },
        (!release.account.inventory) ? undefined : { title: 'Inventory', path: '/account/inventory' },
        (!release.account.activities) ? undefined : { title: 'Activities', path: '/account/activity' },
        (!release.account.settings) ? undefined : { title: 'Settings', path: '/account/settings' },
      ],
    },
  ],
};

export const sidebarConfigNoLogin = [
  (!release.events) ? undefined :
    {
      items: [
        {
          title: 'Events',
          path: '/events/mystery-boxes',
          icon: ICONS.events,
          children: [
            (!release.events.rank_mtb) ? undefined :  { title: 'Rank Fusion', path: '/events/rank-fusion-bomb' },
            (!release.events.mystery_boxes) ? undefined :  { title: 'Mystery Boxes', path: '/events/mystery-boxes' },
            (!release.events.hero_heroes) ? undefined : { title: 'Hero Boxes', path: '/events/hero-boxes' },
          ],
        },
      ],
    },

  (!release.trade) ? undefined :
    {
      subheader: 'Trade',
      items: [
        (!release.trade.boxes) ? undefined : { title: 'Boxes', path: '/trade/boxes', icon: ICONS.boxs },
        (!release.trade.heroes) ? undefined : { title: 'Heroes', path: '/trade/heroes', icon: ICONS.heroes },
        (!release.trade.bombs) ? undefined : { title: 'Bombs', path: '/trade/bombs', icon: ICONS.items },
        (!release.trade.bricks) ? undefined : { title: 'Bricks', path: '/trade/bricks', icon: ICONS.bricks},
        (!release.trade.houses) ? undefined : { title: 'Houses', path: '/trade/houses', icon: ICONS.houses },
        (!release.trade.hotels) ? undefined : { title: 'Hotels', path: '/trade/hotels', icon: ICONS.hotels },
      ],
    },

  (!release.games) ? undefined :
    {
      subheader: 'Minigame',
      items: [
        (!release.games.spinner) ? undefined : { title: 'Spinner', path: '/games/spinner', icon: ICONS.spinner },
      ],
    },
];

export const sidebarConfigLogin = [
  (!release.events) ? undefined :
  {
    items: [
      {
        title: 'Events',
        path: '/events/mystery-boxes',
        icon: ICONS.events,
        children: [
          (!release.events.rank_mtb) ? undefined :  { title: 'Rank Fusion', path: '/events/rank-fusion-bomb' },
          (!release.events.mystery_boxes) ? undefined :  { title: 'Mystery Boxes', path: '/events/mystery-boxes' },
          (!release.events.hero_heroes) ? undefined : { title: 'Hero Boxes', path: '/events/hero-boxes' },
        ],
      },
    ],
  },

  (!release.trade) ? undefined :
  {
    subheader: 'Trade',
    items: [
      (!release.trade.boxes) ? undefined : { title: 'Boxes', path: '/trade/boxes', icon: ICONS.boxs },
      (!release.trade.heroes) ? undefined : { title: 'Heroes', path: '/trade/heroes', icon: ICONS.heroes },
      (!release.trade.bombs) ? undefined : { title: 'Bombs', path: '/trade/bombs', icon: ICONS.items },
      (!release.trade.bricks) ? undefined : { title: 'Bricks', path: '/trade/bricks', icon: ICONS.bricks},
      (!release.trade.houses) ? undefined : { title: 'Houses', path: '/trade/houses', icon: ICONS.houses },
      (!release.trade.hotels) ? undefined : { title: 'Hotels', path: '/trade/hotels', icon: ICONS.hotels },
    ],
  },

  (!release.games) ? undefined :
    {
      subheader: 'Minigame',
      items: [
        (!release.games.spinner) ? undefined : { title: 'Spinner', path: '/games/spinner', icon: ICONS.spinner },
      ],
    },
  
  (!release.account) ? undefined : slideAccount
];


