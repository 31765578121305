
import jwtDecode from 'jwt-decode';
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";

import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
} from "@apollo/client";

import sessionData from "../sessionData";
import { GRAPH_API } from "./link";


const httpLink = createHttpLink({ uri: GRAPH_API, credentials: 'same-origin' });
  
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  
  // if (networkError) console.log(`[Network error]: ${networkError}`);
  if (networkError) {
    console.log(`[Network error]: ${JSON.stringify(networkError)}`);
    const {statusCode} = networkError
    if (statusCode === 401) {// Token invalid or expired
      sessionData.logOut()
    } 
  }
});

const authLink = setContext((_, { headers }) => {
  const token = sessionData.jwToken;
  if (token) {
    const decodedToken = jwtDecode(token);
    if (decodedToken) {
      const { exp } = decodedToken;
      const noww = Math.floor(Date.now() / 1000);
      if (noww > exp) {
        console.log('Outttttttttttttttttttttttttttttttttttttttttttttttttttt')
        sessionData.logOut()
      }
    }
  }
  
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});
  
const link = ApolloLink.from([errorLink, authLink.concat(httpLink)]);
  
export const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

