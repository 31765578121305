// @mui
import * as React from 'react';
import { Grid, Slider, } from '@mui/material';
import {useFilterHero} from '../../hooks/useFilter';

// ----------------------------------------------------------------------

const marks = [
  {
    value: 0,
    label: '1',
  },
  {
    value: 1,
    label: '2',
  },
  {
    value: 2,
    label: '3',
  },
  {
    value: 3,
    label: '4',
  },
  {
    value: 4,
    label: '5',
  },
  {
    value: 5,
    label: '6',
  },
  {
    value: 6,
    label: '7',
  },
];

export default function FilterLevel() {
  const {
    level,
    onChangeLevel
  } = useFilterHero();

  return (
    <Grid dir="ltr" container spacing={0.5} sx={{mx:0}}>
      {
        <Grid key={21} item xs={12} sx={{ mr: 2 }} >
          <Slider
            aria-label="Level"
            defaultValue={1}
            value={level}
            onChange={(event, newValue) => {
              onChangeLevel(newValue);
            }}
            step={1}
            marks={marks}
            min={0}
            max={6}
          />
        </Grid>
      }
    </Grid>
  );
}
