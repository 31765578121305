import { useState } from "react";

export const memoryState = {}

export function useMemoryState(key, initialState) {
    const [state, setState] = useState(() => {
        // eslint-disable-next-line no-prototype-builtins
        const hasMemoryValue = memoryState.hasOwnProperty(key);
        if (hasMemoryValue) {
            return memoryState[key]
        }
        return typeof initialState === 'function' ? initialState() : initialState;
      
    });
  
    function onChange(nextState) {
        memoryState[key] = nextState;
        setState(nextState);
    }
  
    return [state, onChange];
}