import { useContext } from 'react';
import { FilterBoxContext } from '../contexts/FilterBoxContext';
import { FilterHeroContext } from '../contexts/FilterHeroContext';
import { FilterBombContext } from '../contexts/FilterBombContext';
import { FilterItemContext } from '../contexts/FilterItemContext';
import { FilterHouseContext } from '../contexts/FilterHouseContext';
// ----------------------------------------------------------------------

export const useFilterBox = () => useContext(FilterBoxContext);
export const useFilterHero = () => useContext(FilterHeroContext);
export const useFilterBomb = () => useContext(FilterBombContext);
export const useFilterItem = () => useContext(FilterItemContext);
export const useFilterHouse = () => useContext(FilterHouseContext);