import PropTypes from 'prop-types';
import { AnimatePresence, m } from 'framer-motion';
import { useState, useEffect } from 'react';
import { alpha, styled } from '@mui/material/styles';
import { Backdrop, Divider, Typography, Stack, FormControlLabel, Radio } from '@mui/material';
import { useLocation, } from "react-router-dom";
import useSettings from '../../hooks/useSettings';
import cssStyles from '../../utils/cssStyles';
import { NAVBAR, defaultSettings } from '../../config';
import Iconify from '../Iconify';
import Scrollbar from '../Scrollbar';
import { useAccount } from '../../hooks/useAccount';
import { IconButtonAnimate, varFade } from '../animate';
import FilterRarity from './FilterRarity';
import FilterStats from './FilterStats';
import FilterRarityB from './FilterRarityB';

import FilterClass from './FilterClass';
import FilterLevel from './FilterLevel';
import FilterElement from './FilterElement';
import FilterSkill from './FilterSkill';

import ToggleButton from './ToggleButton';

import { useMemoryState } from '../../utils/memoryState';
import {useFilterHero, useFilterBomb} from '../../hooks/useFilter';


// ----------------------------------------------------------------------

const RootStyle = styled(m.div)(({ theme }) => ({
  ...cssStyles(theme).bgBlur({ color: theme.palette.background.paper, opacity: 0.92 }),
  top: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  position: 'fixed',
  overflow: 'hidden',
  width: NAVBAR.BASE_WIDTH,
  flexDirection: 'column',
  margin: theme.spacing(2),
  paddingBottom: theme.spacing(3),
  zIndex: theme.zIndex.drawer + 3,
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  boxShadow: `-24px 12px 32px -4px ${alpha(
    theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.common.black,
    0.16
  )}`,
}));

// ----------------------------------------------------------------------

export default function FilterNFT() {

  const onResetHeroFilter = useFilterHero().onReset;
  const onResetBombFilter = useFilterBomb().onReset;

  const { inventoryTab } = useAccount();

  const [filterType, setFilterType] = useMemoryState('filter', null);

  const { themeMode, themeDirection, themeColorPresets, themeStretch, themeLayout, onResetSetting } = useSettings();

  const [open, setOpen] = useState(false);

  const location = useLocation();
  const lik = location?.pathname?.split("/")[2]

  const notDefault =
    themeMode !== defaultSettings.themeMode ||
    themeDirection !== defaultSettings.themeDirection ||
    themeColorPresets !== defaultSettings.themeColorPresets ||
    themeLayout !== defaultSettings.themeLayout ||
    themeStretch !== defaultSettings.themeStretch;

  const varSidebar =
    themeDirection !== 'rtl'
      ? varFade({
        distance: NAVBAR.BASE_WIDTH,
        durationIn: 0.32,
        durationOut: 0.32,
      }).inRight
      : varFade({
        distance: NAVBAR.BASE_WIDTH,
        durationIn: 0.32,
        durationOut: 0.32,
      }).inLeft;

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [open]);

  useEffect(() => {
    if (lik === 'heroes' || (lik === 'inventory' && inventoryTab === 0)) setFilterType('Heroes')
    else if (lik === 'bombs' || (lik === 'inventory' && inventoryTab === 1)) setFilterType('Bombs')
    else setFilterType('')
  }, [lik, setFilterType]);

  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    filterType !== '' && <>
      <Backdrop
        open={open}
        onClick={handleClose}
        sx={{ background: 'transparent', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      />

      {!open && <ToggleButton open={open} notDefault={notDefault} onToggle={handleToggle} />}

      <AnimatePresence>
        {open && (
          <>
            <RootStyle {...varSidebar}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 1, pr: 1, pl: 2.5 }}>
                <Typography variant="subtitle1">{`Filter ${filterType}`}</Typography>
                <div>
                  <IconButtonAnimate onClick={filterType === 'Heroes' ? onResetHeroFilter : onResetBombFilter}>
                    <Iconify icon={'ic:round-refresh'} width={20} height={20} />
                  </IconButtonAnimate>
                  <IconButtonAnimate onClick={handleClose}>
                    <Iconify icon={'eva:close-fill'} width={20} height={20} />
                  </IconButtonAnimate>
                </div>
              </Stack>

              <Divider sx={{ borderStyle: 'dashed' }} />

              <Scrollbar sx={{ flexGrow: 1 }}>
                <Stack spacing={2} sx={{ p: 2.5 }}>

                  <Stack>
                    <Typography variant="subtitle2">Rarity</Typography>
                    {
                      filterType === 'Heroes' ? <FilterRarity /> : <FilterRarityB />
                    }
                  </Stack>

                  <Stack>
                    {
                      filterType === 'Heroes' ?
                        <>
                          <Typography variant="subtitle2">Class</Typography>
                          <FilterClass />
                        </> :
                        <>
                          <Typography variant="subtitle2">Element</Typography>
                          <FilterElement />
                        </>
                    }
                  </Stack>
                  {
                    filterType === 'Bombs' && <Stack>
                      <Typography variant="subtitle2">Skill</Typography>
                      <FilterSkill />
                    </Stack>
                  }

                  {
                    filterType === 'Heroes' && <Stack>
                      <Typography variant="subtitle2" sx={{ mb: 1.5 }}>Base Stats</Typography>
                      <FilterStats />
                    </Stack>
                  }
                  
                  {
                    filterType === 'Heroes' && <Stack>
                      <Stack>
                        <Typography variant="subtitle2">Level</Typography>
                      </Stack>
                      <FilterLevel />
                    </Stack>
                  }

                </Stack>
              </Scrollbar>
            </RootStyle>
          </>
        )}
      </AnimatePresence>
    </>
  );
}

// ----------------------------------------------------------------------

BoxMask.propTypes = {
  value: PropTypes.string,
};

export function BoxMask({ value }) {
  return (
    <FormControlLabel
      label=""
      value={value}
      control={<Radio sx={{ display: 'none' }} />}
      sx={{
        m: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        position: 'absolute',
      }}
    />
  );
}
