/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
import Web3 from 'web3';
import {useState } from 'react';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'
import { Badge } from '@mui/material';

import WalletConnectProvider from "@walletconnect/web3-provider";
import { client } from "./apollo";
import sessionData from '../sessionData';
import { 
    ITEM_TYPE,
    NETWORK_VERSION,
    NETWORK_CONFIG, 
    NETWORK_VERSION_HEX,
    METAMASK_PROVIDER,
    WALLETCONNECT_PROVIDER,
    MTB_CONTRACT_ADDRESS,
    BUSD_CONTRACT_ADDRESS,
    NFT_HERO_CONTRACT_ADDRESS,
    NFT_BOMB_CONTRACT_ADDRESS,
    STAKE_BOMB_BOX_CONTRACT_ADDRESS,
    STAKE_MYSTERY_BOX_CONTRACT_ADDRESS,
    FAUCET_CONTRACT_BUSD_ADDRESS,
    FAUCET_CONTRACT_MTB_ADDRESS,
    CLAIM_MTB_CONTRACT_ADDRESS,
    BRICK_CONTRACT_ADDRESS,
    NFT_HOUSE_CONTRACT_ADDRESS
} from './constants';
import {
    MTB_CONTRACT_ABI,
    BUSD_CONTRACT_ABI,
    STAKE_NORMAL_BOX_CONTRACT_ABI,
    STAKE_MYSTERY_BOX_CONTRACT_ABI,
    NFT_HERO_CONTRACT_ABI, 
    NFT_BOMB_CONTRACT_ABI,
    FAUCET_CONTRACT_BUSD_ABI,
    FAUCET_CONTRACT_MTB_ABI,
    CLAIM_MTB_CONTRACT_ABI,
    BRICK_CONTRACT_ABI,
    NFT_HOUSE_CONTRACT_ABI
} from '../contracts/contract';
import { getTokenPriceUSD, getTokenPriceUSD2 } from './tokenPrice';
import { JOIN, RANDOM } from "../graphql/userApi";
import { linkToHome, linkToAccount, linkToMarket } from './link';
import { handleResult } from './utils';

const MAX_APPROVE = '115792089237316195423570985008687907853269984665640564039457584007913129639935'

let jwt
let signedMessage

let web3 = null
let provider = null
let providerType = null    

export function AvataAddr() {
    const [addr, setAddr] = useState(sessionData.myAddress)
    return (
        <Badge color="error" overlap="circular" badgeContent="new" invisible={sessionData.userName !== '' && sessionData.userName !== null && sessionData.userName !== undefined}>
            <Jazzicon paperStyles={{ border: '2px solid #fff' }} diameter={36} seed={jsNumberForAddress(addr)} />
        </Badge>
    );
};

// COMMON----------------------------------------------------------------------------------------------------

export const getWeb3 = () => web3
export const getProviderType = () => providerType
export const getBlockNumber = async () => {
    const bl = await web3.eth.getBlockNumber(); return bl
};

const getContract = (abi, address) => (web3 === null) ? null : new web3.eth.Contract(abi, address);

export const updateWeb3Provider = (p) => {
    if (p == METAMASK_PROVIDER) {
        providerType = METAMASK_PROVIDER
        provider = window.ethereum
        web3 = new Web3(provider);
        sessionData.updateProvider(METAMASK_PROVIDER)
    } 
    else if (p == WALLETCONNECT_PROVIDER) {
        providerType = WALLETCONNECT_PROVIDER
        const networkInfo = {
            infuraId: '5f74127583e24c7d83c33a14811b2dfb',
            rpc: {
                // 97: "https://data-seed-prebsc-1-s1.binance.org:8545",
                // 56: "https://bsc-dataseed.binance.org"
            },
        };
        networkInfo.rpc[NETWORK_VERSION] = NETWORK_CONFIG[0].rpcUrls[0];
        provider = new WalletConnectProvider(networkInfo);
        web3 = new Web3(provider);
        sessionData.updateProvider(WALLETCONNECT_PROVIDER)
    } else {
        web3 = new Web3(NETWORK_CONFIG[0].rpcUrls[0]);
    }
    
    if (provider && providerType === METAMASK_PROVIDER) {
        provider.on('accountsChanged', () => { sessionData.logOut(); if (p == WALLETCONNECT_PROVIDER) web3.currentProvider.disconnect()})
        provider.on('chainChanged', () => { sessionData.logOut();if (p == WALLETCONNECT_PROVIDER) web3.currentProvider.disconnect() })
        provider.on('disconnect', () => { sessionData.logOut();if (p == WALLETCONNECT_PROVIDER) web3.currentProvider.disconnect() })
    }
}

export const initWallet = async () => {
    const price = await getTokenPriceUSD2(MTB_CONTRACT_ADDRESS);
    if (sessionData.exchangeRate > 0) {
        // eslint-disable-next-line no-unused-expressions
        price > 0 && sessionData.updateRateUSD(price);    
    } else {
        sessionData.updateRateUSD(price);    
    }
    updateWeb3Provider(sessionData.provider)
    if (provider && providerType == WALLETCONNECT_PROVIDER) {
        if (!provider.wc.connected) {
            sessionData.logOut()
        } else if (!sessionData.isLoggedIn()) {
            await provider.disconnect()
        } else {
            await provider.enable()
        }
    }
    if (provider && providerType == METAMASK_PROVIDER) {
        await provider.enable()
    }
}

export const requestAdressAndApproveToken = () => {
    allowance(MTB_CONTRACT_ADDRESS)
}

export const getBalanceAddr = (addr) => {
    if (web3 === null) return 0
    const contract = getContract(MTB_CONTRACT_ABI, MTB_CONTRACT_ADDRESS);
    return contract&&contract.methods.balanceOf(addr).call().then(result => web3.utils.fromWei(result, "ether"));
}

export const getMTBBalance = () => {
    if (web3 === null) return 0
    const contract = getContract(MTB_CONTRACT_ABI, MTB_CONTRACT_ADDRESS);
    return contract&&contract.methods.balanceOf(getWalletAddress()).call().then(result => web3.utils.fromWei(result, "ether"));
}


export const getBrickBalance = () => {
    if (web3 === null) return 0
    const contract = getContract(BRICK_CONTRACT_ABI, BRICK_CONTRACT_ADDRESS);
    return contract && contract.methods.balanceOf(getWalletAddress(), 0).call().then(result => result);
}



export const getBUSDBalance = () => {
    if (web3 === null) return 0
    const contract = getContract(BUSD_CONTRACT_ABI, BUSD_CONTRACT_ADDRESS);
    return contract&&contract.methods.balanceOf(getWalletAddress()).call().then(result => web3.utils.fromWei(result, "ether"));
}

export const getWalletAddressAsync = async () => {
    try {
        const accounts = await web3.eth.getAccounts();
        const myAddress = accounts[0]
        if (myAddress) { 
            // sessionData.updateAddress(myAddress)
            sessionData.updateProvider(providerType)
            return myAddress
        }
    } catch (error) {
        console.error(error);
    }
    return null
}

export const getWalletAddress = () => {
    if (!sessionData.myAddress) return getWalletAddressAsync()
    return sessionData.myAddress
}

export const addBSCNetwork = async () => { await web3.currentProvider.request({ method: 'wallet_addEthereumChain', params: NETWORK_CONFIG }).catch()}

export const switchToBSCNetwork = async (myHistoty = null) => {
    try {
        await web3.currentProvider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: NETWORK_VERSION_HEX }],
        });
        // nếu truyền history vào thì refresh, chỉ làm 1 lần tránh lặp vô tận
        if (myHistoty) {
            myHistoty.push(linkToHome);
            myHistoty.goBack();
        }
    } catch (switchError) {
        if (switchError.code === 4902) {
            console.log("Chain was not added to Metamask")
            try {
                await web3.currentProvider.request({
                    method: 'wallet_addEthereumChain',
                    params: NETWORK_CONFIG,
                });
            } catch (addError) {
                // handle "add" error
                console.log("Error adding Chain to Metamask")
                console.log(addError)
            }
        }
    }
}

export const getNetworkVersion = () => {
    if (!web3) return -1000000
    // console.log(providerType, web3.currentProvider)
    return providerType == METAMASK_PROVIDER ? web3.currentProvider.networkVersion : web3.currentProvider.chainId
}

export const getJWT = () => {
    (async () => {
        const { data, loading, error } = await client.query({ query: RANDOM, });
        if (error) {
            alert('System Error!')
        } else {
            jwt = data.random;
            // console.log(`JWT: ${jwt}`)
            requestConnectWallet()
        }
    })();
}

const requestSignAndRegister = (myAddress) => {
    const msgParams = JSON.stringify({
        domain: {
            // Defining the chain aka Rinkeby testnet or Ethereum Main Net
            chainId: NETWORK_VERSION,
            // Give a user friendly name to the specific contract you are signing for.
            name: 'Metabomb.io',
            // If name isn't enough add verifying contract to make sure you are establishing contracts with the proper entity
            verifyingContract: '0xCcCCccccCCCCcCCCCCCcCcCccCcCCCcCcccccccC',
            // Just let's you know the latest version. Definitely make sure the field name is correct.
            version: '1',
        },

        // Defining the message signing data content.
        message: {
            title: "Verify your Wallet Address",  
            contents: `Noise: ${jwt}`,
        },
        
        // Refers to the keys of the *types* object below.
        primaryType: 'Mail',
        types: {
            // TODO: Clarify if EIP712Domain refers to the domain the contract is hosted on
            EIP712Domain: [
                { name: 'name', type: 'string' },
                { name: 'version', type: 'string' },
                { name: 'chainId', type: 'uint256' },
                { name: 'verifyingContract', type: 'address' },
            ],
            Mail: [
                { name: 'title', type: 'string' },
                { name: 'contents', type: 'string' },
            ],
        },
    });
    const from = myAddress;
    const params = [from, msgParams];
    const method = 'eth_signTypedData_v4';
    web3.currentProvider.sendAsync(
        {
            method,
            params,
            from,
        },
        (err, result) => {
            if (err) {
                alert(err.message);
                return console.dir(err);
            }
            if (result.error) {
                alert(result.error.message);
            }
            if (result.error) return console.error('ERROR', result);

            signedMessage = result.result;

            // console.log(`TYPED SIGNED:${signedMessage}`);
            
            register(msgParams, myAddress);
        }
    );
}

const register = (msgParams, myAddress) => {
    (async () => {
        const { data, loading, error } = await client.mutate({
            mutation: JOIN,
            variables: {
                input: {
                    address: myAddress,
                    message: msgParams,
                    sign: signedMessage,
                    public_key: "none"
                }
            }
        });

        if (error) {
            alert('System Error!')
        } else {
            const { id, user_name, token } = data.join
            sessionData.setSessionData(id, user_name, token, myAddress, providerType)
            if (user_name && user_name !== '') {
                window.location = linkToMarket
            } else {
                window.location = linkToAccount
            }
        }
    })();
}

const getWalletAccounts = async () => {
    // const myAddress = await getWalletAddressAsync()
    // sessionData.setSessionData(undefined, undefined, undefined, myAddress, providerType)
    // window.location = linkToMarketBox
    const myAddress = await getWalletAddressAsync()
    if (myAddress) requestSignAndRegister(myAddress)
}

export const requestConnectWallet = async () => {
    try {
        await web3.currentProvider.enable()
        getWalletAccounts()
    } catch (error) {
        console.error(error);
    }
}

// STAKE-------------------------------------------------------------------------------------------------------
    
export const allowance = (contractAddress) => getContract(MTB_CONTRACT_ABI, MTB_CONTRACT_ADDRESS).methods.allowance(getWalletAddress(), contractAddress).call().then(amount => amount > 0)
export const allowanceAmount = (contractAddress) => getContract(MTB_CONTRACT_ABI, MTB_CONTRACT_ADDRESS).methods.allowance(getWalletAddress(), contractAddress).call().then(amount => web3.utils.fromWei(amount, "ether"))
export const allowanceBrick = (contractAddress) => getContract(BRICK_CONTRACT_ABI, BRICK_CONTRACT_ADDRESS).methods.isApprovedForAll(getWalletAddress(), contractAddress).call().then(ret => ret)

export const approve = (contractAddress) => {
    const contract = getContract(MTB_CONTRACT_ABI, MTB_CONTRACT_ADDRESS);
    const amount = `${web3.utils.toHex(MAX_APPROVE)}`;
    return contract.methods.approve(contractAddress, amount).send({ from: getWalletAddress() })
}
export const approveBrick = async (contractAddress) => {
    const contract = getContract(BRICK_CONTRACT_ABI, BRICK_CONTRACT_ADDRESS);
    const ret = await contract.methods.setApprovalForAll(contractAddress, true).send({ from: getWalletAddress() });
    return ret;
}

export const allowanceBUSD = (contractAddress) => getContract(BUSD_CONTRACT_ABI, BUSD_CONTRACT_ADDRESS).methods.allowance(getWalletAddress(), contractAddress).call().then(amount => amount > 0)
export const approveBUSD = (contractAddress) => {
    const contract = getContract(BUSD_CONTRACT_ABI, BUSD_CONTRACT_ADDRESS);
    const amount = `${web3.utils.toHex(MAX_APPROVE)}`;
    return contract.methods.approve(contractAddress, amount).send({ from: getWalletAddress() })
}

export const getEndTimeStake = async (ABI, ADDR) => getContract(ABI, ADDR).methods.END_TIME().call().then(result => result);
export const getTokenStakePerBox = async (ABI, ADDR) => getContract(ABI, ADDR).methods.tokenStakePerBox().call().then(result => result);
export const getTotalMintedBox = async (ABI,ADDR) => getContract(ABI, ADDR).methods.getTotalMintedBox().call().then(result => result);
export const getMaxBoxPerUser = async (ABI, ADDR) => getContract(ABI, ADDR).methods.maxBoxPerUser().call().then(result => result);
export const getTotalStake = async (ABI,ADDR) => getContract(ABI,ADDR).methods.getTotalStaked().call().then(result => web3.utils.fromWei(result, "ether"));
export const getAvailableBoxToSwap = async (ABI, ADDR) => getContract(ABI, ADDR).methods.getAvailableBoxToSwap(getWalletAddress()).call().then(result => result);
export const getUser = async (ABI, ADDR) => getContract(ABI, ADDR).methods.getUserInfo(getWalletAddress()).call().then(result => result);

export const getWithDrawTime = async (ABI, ADDR) => getContract(ABI, ADDR).methods.getUserWithdrawTime(getWalletAddress()).call().then(result => result);

export const stakeWithdraw = async (ABI, ADDR, enqueueSnackbar, onComplete, onError) => {
    const contract = getContract(ABI, ADDR);
    handleResult(contract.methods.withdraw(), enqueueSnackbar, onComplete, onError)
}

export const stakeMTBBuyBox = async (ABI, ADDR, amount, enqueueSnackbar, onComplete, onError) => {
    const contract = getContract(ABI, ADDR);
    const amountWei = web3.utils.toWei(amount)
    handleResult(contract.methods.deposit(amountWei), enqueueSnackbar, onComplete, onError)
}

export const buyBox = async (ABI, ADDR, amount, enqueueSnackbar, onComplete, onError) => {
    const contract = getContract(ABI, ADDR);
    handleResult(contract.methods.swapBox(amount), enqueueSnackbar, onComplete, onError)
}

export const buyBoxB = async (ABI, ADDR, amount, enqueueSnackbar, onComplete, onError) => {
    const contract = getContract(ABI, ADDR);
    handleResult(contract.methods.buyBox(amount), enqueueSnackbar, onComplete, onError)
}

export const infoUserStake = async () => {
    const contract = getContract(STAKE_NORMAL_BOX_CONTRACT_ABI, STAKE_NORMAL_BOX_CONTRACT_ABI);
    return contract && contract.methods.users(getWalletAddress()).call().then(result => result);
}

export const stakeMTBFreeBoxInGame = async (type, amount, enqueueSnackbar, onComplete, onError) => {
    const contract = getContract(STAKE_MYSTERY_BOX_CONTRACT_ABI, STAKE_MYSTERY_BOX_CONTRACT_ADDRESS);
    const amountWei = web3.utils.toWei(amount)
    if (type === 1) {
        handleResult(contract.methods.depositPool1(amountWei), enqueueSnackbar, onComplete, onError)
    } else {
        handleResult(contract.methods.depositPool2(amountWei), enqueueSnackbar, onComplete, onError)
    }
}

export const getEndTimeStakePool = async (type) => {
    if (type === 1) {
        return getContract(STAKE_MYSTERY_BOX_CONTRACT_ABI, STAKE_MYSTERY_BOX_CONTRACT_ADDRESS).methods.pool1EndDate().call().then(result => result);
    }
    return getContract(STAKE_MYSTERY_BOX_CONTRACT_ABI, STAKE_MYSTERY_BOX_CONTRACT_ADDRESS).methods.pool2EndDate().call().then(result => result);
}

export const getMTBPerBoxPool = async (type) => {
    if (type === 1) {
        return getContract(STAKE_MYSTERY_BOX_CONTRACT_ABI, STAKE_MYSTERY_BOX_CONTRACT_ADDRESS).methods.pool1StakeAmountPerBox().call().then(result => web3.utils.fromWei(result, "ether"));
    }
    return getContract(STAKE_MYSTERY_BOX_CONTRACT_ABI, STAKE_MYSTERY_BOX_CONTRACT_ADDRESS).methods.pool2StakeAmountPerBox().call().then(result => web3.utils.fromWei(result, "ether"));
}

export const getTotalStakePool = async (type) => {
    if (type === 1) {
        return getContract(STAKE_MYSTERY_BOX_CONTRACT_ABI, STAKE_MYSTERY_BOX_CONTRACT_ADDRESS).methods.pool1StakeAmount().call().then(result => web3.utils.fromWei(result, "ether"));
    }
    return getContract(STAKE_MYSTERY_BOX_CONTRACT_ABI, STAKE_MYSTERY_BOX_CONTRACT_ADDRESS).methods.pool2StakeAmount().call().then(result => web3.utils.fromWei(result, "ether"));
}

export const getPool2BoxCount = async () => getContract(STAKE_MYSTERY_BOX_CONTRACT_ABI, STAKE_MYSTERY_BOX_CONTRACT_ADDRESS).methods.pool2BoxCount().call().then(result => result);

export const getUserStakeing = async (type) => getContract(STAKE_MYSTERY_BOX_CONTRACT_ABI, STAKE_MYSTERY_BOX_CONTRACT_ADDRESS).methods.userStaking(type, getWalletAddress()).call().then(result => result)

export const stakePoolWithdraw = async (type, enqueueSnackbar, onComplete, onError) => {
    const contract = getContract(STAKE_MYSTERY_BOX_CONTRACT_ABI, STAKE_MYSTERY_BOX_CONTRACT_ADDRESS);
    handleResult(contract.methods.withdraw(type), enqueueSnackbar, onComplete, onError)
}


// TRADE-BOX---------------------------------------------------------------------------------------------------
export const putOnSale = async (ABI, ADDR, tokenId, price, enqueueSnackbar, onComplete, onError) => {
    const contract = getContract(ABI, ADDR);
    const priceWei = web3.utils.toWei(price)
    handleResult(contract.methods.putOnSale(tokenId, priceWei), enqueueSnackbar, onComplete, onError)
}

export const cancelSale = async (ABI, ADDR, tokenId, enqueueSnackbar, onComplete, onError) => {
    const contract = getContract(ABI, ADDR);
    handleResult(contract.methods.cancelSale(tokenId), enqueueSnackbar, onComplete, onError)
}

export const buySaleBox = async (ABI, ADDR, tokenId, price, enqueueSnackbar, onComplete, onError) => {
    const contract = getContract(ABI, ADDR);
    const sellPrice = web3.utils.toHex(web3.utils.toWei(price.toString(), 'ether'))
    handleResult(contract.methods.buySaleBox(tokenId, sellPrice), enqueueSnackbar, onComplete, onError)
}

export const openBoxHero = async (boxId, boxType, enqueueSnackbar, onComplete, onError) => {
    const contract = getContract(NFT_HERO_CONTRACT_ABI, NFT_HERO_CONTRACT_ADDRESS);
    handleResult(contract.methods.openBox(boxId, boxType), enqueueSnackbar, onComplete, onError)
}

export const openBoxBomb = async (boxId, enqueueSnackbar, onComplete, onError) => {
    const contract = getContract(NFT_BOMB_CONTRACT_ABI, NFT_BOMB_CONTRACT_ADDRESS);
    handleResult(contract.methods.openBox(boxId), enqueueSnackbar, onComplete, onError)
}

export const onMintHero = async (fromBlock, boxType) => {
    const contract = getContract(NFT_HERO_CONTRACT_ABI, NFT_HERO_CONTRACT_ADDRESS);

    const options = {
        filter: {
            user: getWalletAddress().toLowerCase(),
            boxType
        },
        fromBlock 
    };

    const result = await contract.getPastEvents('MintHero', options);
    // console.log(result)
    if (result && result[0] && result[0].returnValues) {
        return result[0].returnValues.tokenId
    } return null;
}

export const onMintBomb = async (fromBlock, boxType) => {
    const contract = getContract(NFT_BOMB_CONTRACT_ABI, NFT_BOMB_CONTRACT_ADDRESS);
    const options = {
        filter: {
            user: getWalletAddress().toLowerCase(),
            boxType
        },
        fromBlock 
    };

    const result = await contract.getPastEvents('MintBomb', options);
    // console.log(result)
    if (result && result[0] && result[0].returnValues) {
        return result[0].returnValues.tokenId
    } return null;
}

export const approveBox = async (ABI, ADDR, ADDRNFT) => {
    const contract = getContract(ABI, ADDR);
    const ret = await contract.methods.setApprovalForAll(ADDRNFT, true).send({ from: getWalletAddress() });
    return ret;
}

export const isApprovedBox = (ABI, ADDR, ADDRNFT) => getContract(ABI, ADDR).methods.isApprovedForAll(getWalletAddress(), ADDRNFT).call().then(ret => ret);


// TRADE-HERO--------------------------------------------------------------------------------------------------
export const buyHeroNFT = (heroId,price, enqueueSnackbar,onComplete,onError) => {
    const contract = getContract(NFT_HERO_CONTRACT_ABI, NFT_HERO_CONTRACT_ADDRESS);
    const sellPrice = web3.utils.toHex(web3.utils.toWei(price.toString(), 'ether'))
    handleResult(contract.methods.buySaleHero(heroId, sellPrice), enqueueSnackbar, onComplete,onError);
}

export const putHeroNFTOnSale = (heroId, heroPrice, heroAge, timestamp, sign, enqueueSnackbar, onComplete, onError) => {
    if (!heroPrice) return
    const contract = getContract(NFT_HERO_CONTRACT_ABI, NFT_HERO_CONTRACT_ADDRESS);
    const sellPrice = web3.utils.toHex(web3.utils.toWei(heroPrice, 'ether'))
    handleResult(contract.methods.putOnSale(heroId, sellPrice, heroAge, timestamp, sign), enqueueSnackbar, onComplete, onError)
}

export const cancelSaleHeroNFT = (heroId,enqueueSnackbar,onComplete,onError) => {
    const contract = getContract(NFT_HERO_CONTRACT_ABI, NFT_HERO_CONTRACT_ADDRESS);
    handleResult( contract.methods.cancelSale(heroId),enqueueSnackbar,onComplete,onError)
}


// TRADE-BOMB-------------------------------------------------------------------------------------------------
export const buyBombNFT = (bombId, price , enqueueSnackbar,onComplete) => {
    const contract = getContract(NFT_BOMB_CONTRACT_ABI, NFT_BOMB_CONTRACT_ADDRESS);

    const sellPrice = web3.utils.toHex(web3.utils.toWei(price.toString(), 'ether'))

    handleResult( contract.methods.buySaleBomb(bombId, sellPrice),enqueueSnackbar,onComplete)
}

export const putBombNFTOnSale = (bombId, bombPrice, timestamp, sign, enqueueSnackbar, onComplete, onError) => {
    if (!bombPrice) return
    const contract = getContract(NFT_BOMB_CONTRACT_ABI, NFT_BOMB_CONTRACT_ADDRESS);
    const sellPrice = web3.utils.toHex(web3.utils.toWei(bombPrice, 'ether'))
    handleResult(contract.methods.putOnSale(bombId, sellPrice, timestamp, sign), enqueueSnackbar, onComplete, onError)
}

export const cancelSaleBombNFT = (bombId,enqueueSnackbar,onComplete) => {
    const contract = getContract(NFT_BOMB_CONTRACT_ABI, NFT_BOMB_CONTRACT_ADDRESS);
    handleResult( contract.methods.cancelSale(bombId),enqueueSnackbar,onComplete)
}


// FAUCET-----------------------------------------------------------------------------------------------------
export const getAllowedToWithdraw = async (token) => {
    const ABI = token === 0 ? FAUCET_CONTRACT_MTB_ABI : FAUCET_CONTRACT_BUSD_ABI;
    const ADD = token === 0 ? FAUCET_CONTRACT_MTB_ADDRESS : FAUCET_CONTRACT_BUSD_ADDRESS;
    const contract = getContract(ABI, ADD);
    return contract && contract.methods.allowedToWithdraw(getWalletAddress()).call().then(result => result);
}

export const getTokenAmount = async (token) => {
    const ABI = token === 0 ? FAUCET_CONTRACT_MTB_ABI : FAUCET_CONTRACT_BUSD_ABI;
    const ADD = token === 0 ? FAUCET_CONTRACT_MTB_ADDRESS : FAUCET_CONTRACT_BUSD_ADDRESS;
    const contract = getContract(ABI, ADD);
    return contract && contract.methods.tokenAmount().call().then(result => web3.utils.fromWei(result, "ether"));
}

export const requestTokens = async (token, enqueueSnackbar, onComplete) => {
    const ABI = token === 0 ? FAUCET_CONTRACT_MTB_ABI : FAUCET_CONTRACT_BUSD_ABI;
    const ADD = token === 0 ? FAUCET_CONTRACT_MTB_ADDRESS : FAUCET_CONTRACT_BUSD_ADDRESS;
    const contract = getContract(ABI, ADD);
    handleResult(contract.methods.requestTokens(), enqueueSnackbar, onComplete)
}


// MINT-BY-MTB------------------------------------------------------------------------------------------------
export const buyHero = async (typeBox, amount, enqueueSnackbar, onComplete) => {
    const contract = getContract(NFT_HERO_CONTRACT_ABI, NFT_HERO_CONTRACT_ADDRESS);
    handleResult(contract.methods.buyHero(typeBox, amount), enqueueSnackbar, onComplete);
}

export const buyBomb = async (amount, enqueueSnackbar, onComplete) => {
    const contract = getContract(NFT_BOMB_CONTRACT_ABI, NFT_BOMB_CONTRACT_ADDRESS);
    handleResult(contract.methods.buyBomb(amount), enqueueSnackbar, onComplete);
}



// CLAIM/DEPOSIT------------------------------------------------------------------------------------------------------
export const claimMTB = async (amount, timestamp, logId, sign, enqueueSnackbar, onComplete, onError) => {
    const contract = getContract(CLAIM_MTB_CONTRACT_ABI, CLAIM_MTB_CONTRACT_ADDRESS);
    const amountWei = web3.utils.toWei(amount.toString(), 'ether');
    handleResult(contract.methods.claim(amountWei, timestamp, logId, sign), enqueueSnackbar, onComplete, onError);
}

export const depositMTB = async (amount, timestamp, sign, enqueueSnackbar, onComplete, onError) => {
    const contract = getContract(CLAIM_MTB_CONTRACT_ABI, CLAIM_MTB_CONTRACT_ADDRESS);
    const amountWei = web3.utils.toWei(amount.toString(), 'ether');
    handleResult(contract.methods.deposit(amountWei, timestamp, sign), enqueueSnackbar, onComplete, onError);
}

export const claimBOX = async (ABI, ADDR, amount, timestamp, logId, sign, enqueueSnackbar, onComplete, onError) => {
    const contract = getContract(ABI, ADDR);
    handleResult(contract.methods.claim(amount.toString(), timestamp, logId, sign), enqueueSnackbar, onComplete, onError);
}

export const claimBRICK = async (amount, timestamp, logId, sign, enqueueSnackbar, onComplete, onError) => {
    const contract = getContract(BRICK_CONTRACT_ABI, BRICK_CONTRACT_ADDRESS);
    // const amountWei = web3.utils.toWei(amount.toString(), 'ether');
    handleResult(contract.methods.claim(ITEM_TYPE.BRICK, amount, timestamp, logId, sign), enqueueSnackbar, onComplete, onError);
}

export const depositBrick = async (amount, timestamp, sign, enqueueSnackbar, onComplete, onError) => {
    const contract = getContract(BRICK_CONTRACT_ABI, BRICK_CONTRACT_ADDRESS);
    handleResult(contract.methods.deposit(0, amount, timestamp, sign), enqueueSnackbar, onComplete, onError);
}


// TRADE-BRICK---------------------------------------------------------------------------------------------------

export const putOnSale1155 = async (itemType, quantity, price, packageId, timestamp, sign, enqueueSnackbar, onComplete, onError) => {
    const contract = getContract(BRICK_CONTRACT_ABI, BRICK_CONTRACT_ADDRESS);
    const priceWei = web3.utils.toWei(price)
    handleResult(contract.methods.putOnSale(itemType, quantity, priceWei, packageId, timestamp, sign), enqueueSnackbar, onComplete, onError)
}

export const cancelSale1155 = async (saleNo, packageId, enqueueSnackbar, onComplete, onError) => {
    const contract = getContract(BRICK_CONTRACT_ABI, BRICK_CONTRACT_ADDRESS);
    handleResult(contract.methods.cancelSale(saleNo, packageId), enqueueSnackbar, onComplete, onError)
}

export const buySale1155 = async (saleNo, packageId, price, enqueueSnackbar, onComplete, onError) => {
    const contract = getContract(BRICK_CONTRACT_ABI, BRICK_CONTRACT_ADDRESS);
    const sellPrice = web3.utils.toHex(web3.utils.toWei(price.toString(), 'ether'))
    handleResult(contract.methods.buySaleItem(saleNo, packageId, sellPrice), enqueueSnackbar, onComplete, onError)
}


// FUSION-LEVELUP---------------------------------------------------------------------------------------------------
export const fusionHero = (hero1, hero2, timestamp, sign, enqueueSnackbar, onComplete, onError) => {
    const contract = getContract(NFT_HERO_CONTRACT_ABI, NFT_HERO_CONTRACT_ADDRESS);
    handleResult(contract.methods.fusion(
        hero1.id,
        hero1.rarity,
        hero1.level,
        hero1.lock_until,
        hero2.id,
        hero2.rarity,
        hero2.level,
        hero2.lock_until,
        timestamp,
        sign
    ), enqueueSnackbar, onComplete, onError);
}

export const levelUpHero = (hero1, hero2, timestamp, sign, enqueueSnackbar, onComplete, onError) => {
    const contract = getContract(NFT_HERO_CONTRACT_ABI, NFT_HERO_CONTRACT_ADDRESS);
    
    handleResult(contract.methods.levelUp(
        hero1.id.toString(),
        hero1.rarity.toString(),
        hero1.level.toString(),
        hero1.lock_until.toString(),
        hero2.id.toString(),
        hero2.rarity.toString(),
        hero2.level.toString(),
        hero2.lock_until.toString(),
        timestamp,
        sign
    ), enqueueSnackbar, onComplete, onError);
}

export const fusionBomb = (bomb1, bomb2, timestamp, sign, enqueueSnackbar, onComplete, onError) => {
    const contract = getContract(NFT_BOMB_CONTRACT_ABI, NFT_BOMB_CONTRACT_ADDRESS);
    handleResult(contract.methods.fusion(
        bomb1.id,
        bomb1.rarity,
        bomb1.lock_until,
        bomb2.id,
        bomb2.rarity,
        bomb2.lock_until,
        timestamp,
        sign
    ), enqueueSnackbar, onComplete, onError);
}

export const exchangeBomb = (listBomb, enqueueSnackbar, onComplete, onError) => {
    const contract = getContract(NFT_BOMB_CONTRACT_ABI, NFT_BOMB_CONTRACT_ADDRESS);
    handleResult(contract.methods.exchange(listBomb), enqueueSnackbar, onComplete, onError);
}

export const batchOpenBox = (listBox, enqueueSnackbar, onComplete, onError) => {
    const contract = getContract(NFT_BOMB_CONTRACT_ABI, NFT_BOMB_CONTRACT_ADDRESS);
    handleResult(contract.methods.batchOpenBox(listBox), enqueueSnackbar, onComplete, onError);
}


// HOUSE------------------------------------------------------------------------------------------------------------
export const getMaxUpgradeHouse = async (level) => getContract(NFT_HOUSE_CONTRACT_ABI, NFT_HOUSE_CONTRACT_ADDRESS).methods.maxLevelQtyCount(level).call().then(result => result);
export const getCountUpgradeHouse = async (level) => getContract(NFT_HOUSE_CONTRACT_ABI, NFT_HOUSE_CONTRACT_ADDRESS).methods.levelQtyCount(level).call().then(result => result);
export const getEndtimeHouseEvent = async (level) => getContract(NFT_HOUSE_CONTRACT_ABI, NFT_HOUSE_CONTRACT_ADDRESS).methods.endTime().call().then(result => result);
export const getStarttimeHouseEvent = async (level) => getContract(NFT_HOUSE_CONTRACT_ABI, NFT_HOUSE_CONTRACT_ADDRESS).methods.startTime().call().then(result => result);


export const buildHouse = (qty,enqueueSnackbar, onComplete, onError) => {
    const contract = getContract(NFT_HOUSE_CONTRACT_ABI, NFT_HOUSE_CONTRACT_ADDRESS);
    handleResult(contract.methods.buy(qty), enqueueSnackbar, onComplete, onError);
}

export const upgradeHouse = (id,enqueueSnackbar, onComplete, onError) => {
    const contract = getContract(NFT_HOUSE_CONTRACT_ABI, NFT_HOUSE_CONTRACT_ADDRESS);
    handleResult(contract.methods.upgrade(id), enqueueSnackbar, onComplete, onError);
}

export const putHouseNFTOnSale = (id, price, timestamp, lockUntil, sign, enqueueSnackbar, onComplete, onError) => {
    const contract = getContract(NFT_HOUSE_CONTRACT_ABI, NFT_HOUSE_CONTRACT_ADDRESS);
    const sellPrice = web3.utils.toHex(web3.utils.toWei(price, 'ether'))
    handleResult(contract.methods.putOnSale(id, sellPrice, timestamp, lockUntil, sign), enqueueSnackbar, onComplete, onError)
}

export const putHouseNFTOnSaleT = (id, price, enqueueSnackbar, onComplete, onError) => {
    const contract = getContract(NFT_HOUSE_CONTRACT_ABI, NFT_HOUSE_CONTRACT_ADDRESS);
    const sellPrice = web3.utils.toHex(web3.utils.toWei(price, 'ether'))
    handleResult(contract.methods.putOnSaleTest(id, sellPrice), enqueueSnackbar, onComplete, onError)
}

export const cancelSaleHouseNFT = (id,enqueueSnackbar,onComplete) => {
    const contract = getContract(NFT_HOUSE_CONTRACT_ABI, NFT_HOUSE_CONTRACT_ADDRESS);
    handleResult( contract.methods.cancelSale(id),enqueueSnackbar,onComplete)
}

export const buyHouseNFT = (id, price , enqueueSnackbar,onComplete) => {
    const contract = getContract(NFT_HOUSE_CONTRACT_ABI, NFT_HOUSE_CONTRACT_ADDRESS);
    const sellPrice = web3.utils.toHex(web3.utils.toWei(price.toString(), 'ether'))
    handleResult( contract.methods.buySaleItem(id, sellPrice),enqueueSnackbar,onComplete)
}

// ---------------------------------------------------------------------------------------------------------------
export const CALL_TEST = async (enqueueSnackbar, onComplete, onError) => {
    // const contract = getContract(BRICK_CONTRACT_ABI, BRICK_CONTRACT_ADDRESS);
    const contract = getContract(NFT_BOMB_CONTRACT_ABI, NFT_BOMB_CONTRACT_ADDRESS);
    handleResult(contract.methods.setBrickFusionFees([100,300,600,1000,1500]), enqueueSnackbar, onComplete, onError)
}



