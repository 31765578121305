/* eslint-disable eqeqeq */

import { Button } from '@mui/material';
import React, { Fragment } from 'react';
import { useSnackbar } from "notistack";
import { NETWORK_VERSION, WALLETCONNECT_PROVIDER } from '../../utils/constants';
import { getJWT, updateWeb3Provider,getNetworkVersion, getWeb3,switchToBSCNetwork } from '../../utils/cryptoWallet';

export default function LoginWalletConnectButton() {
    
    const { enqueueSnackbar } = useSnackbar();

    const handleClickConnect = async () => {
        updateWeb3Provider(WALLETCONNECT_PROVIDER)
        try {
            const webb3 = getWeb3()
            const provider = webb3.currentProvider;
            await provider.enable()
            const networkVersion = Number(getNetworkVersion());
            // console.log(networkVersion)
            if (networkVersion != NETWORK_VERSION) {
                enqueueSnackbar("Please change to BSC network", { variant: 'warning' });
                // switchToBSCNetwork()
                await provider.disconnect()
            } else {
                getJWT()
            }
            // eslint-disable-next-line no-empty
        } catch {

        }
    }

    return (
        <>
            <Button variant="outlined" color="primary" fullWidth size="large"
                startIcon={<img src="/icons/walletconnect.png" alt='walletconnect' height={32} />}
                onClick={handleClickConnect}>
                WalletConnect
            </Button>
        </>
    )
}




