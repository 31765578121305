// scroll bar
import 'simplebar/src/simplebar.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// contexts
import { FilterBoxProvider } from './contexts/FilterBoxContext';
import { FilterHeroProvider } from './contexts/FilterHeroContext';
import { FilterBombProvider } from './contexts/FilterBombContext';
import { FilterItemProvider } from './contexts/FilterItemContext';
import { FilterHouseProvider } from './contexts/FilterHouseContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';


//
import App from './App';

// ----------------------------------------------------------------------

ReactDOM.render(
  <HelmetProvider>
    <SettingsProvider>
      <FilterItemProvider>
        <FilterBoxProvider>
          <FilterHouseProvider>
            <FilterHeroProvider>
              <FilterBombProvider>
                <CollapseDrawerProvider>
                  <BrowserRouter>
                    <App />
                  </BrowserRouter>
                </CollapseDrawerProvider>
              </FilterBombProvider>
            </FilterHeroProvider>
          </FilterHouseProvider>
        </FilterBoxProvider>
      </FilterItemProvider>
    </SettingsProvider>
  </HelmetProvider>,
  document.getElementById('root')
);