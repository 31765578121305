import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
// components
import LoadingScreen from '../components/LoadingScreen';
import { TEST_NET } from '../utils/constants';
import { release } from '../featureConfig';
import sessionData from '../sessionData';
// ----------------------------------------------------------------------
// TODO : điều chỉnh lại router trước và sau đăng nhập

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const timeOpen = new Date(1656856800000); 
  const show = localStorage.getItem("show")

  const routArrLogin = [
    {
      path: '/',
      element: <Navigate to="/events" replace />,
    },
    {
      path: '*',
      exact: true,
      element: <Navigate to="/trade" replace/>,
    },
    {
      path: '/MAONYOT5C4',
      element: <TestFunction />,
    },
    {
      path: '/register',
      element: <Navigate to="/trade/heroes/connect-wallet" replace />,
    },
    {
      path: '/events',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/events/mystery-boxes" replace />, index: true },
        // { path: 'rank-fusion-bomb', element: <RankFusionBomb /> },
        { path: 'hero-boxes', element: <MarketBoxs /> },
        // { path: 'bomb-boxes', element: Date.now() > timeOpen ? <MarketBombBoxes /> : <ComingSoon /> },
        { path: 'mystery-boxes', element: Date.now() > timeOpen ? <BoxFreeMystery /> : <ComingSoon /> },
      ],
    },
    {
      path: '/trade',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/trade/heroes" replace />, index: true },
        { path: 'boxes', element: <MarketBoxes /> },
        { path: 'heroes', element: <MarketHeroes /> },
        { path: 'heroes/connect-wallet', element: <MarketHeroes /> },
        { path: 'bombs', element: <MarketItems /> },
        { path: 'bricks', element: <MarketBricks /> },
        { path: 'houses', element: <MarketHouses /> },
        // { path: 'hotels', element: <MarketHotels /> },
      ],
    },
    {
      path: '/hero',
      element: <DashboardLayout />,
      children: [
        { path: ':id', element: <MarketHeroDetail /> },
      ]
    },
    {
      path: '/bomb',
      element: <DashboardLayout />,
      children: [
        { path: ':id', element: <MarketItemsDetail /> },
      ]
    },
    {
      path: '/house',
      element: <DashboardLayout />,
      children: [
        { path: ':id', element: <MarketHousesDetail /> },
      ]
    },
    {
      path: '/user',
      element: <DashboardLayout />,
      children: [
        { path: ':id', element: <AccountInventoryOther /> },
      ]
    },
    // {
    //   path: '/games',
    //   element: <DashboardLayout />,
    //   children: [
    //     { element: <Navigate to="/games/spinner" replace />, index: true },
    //     { path: 'spinner', element: <MinigameSpinner /> },
    //   ],
    // },
    {
      path: '/account',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/account/inventory" replace />, index: true },
        { path: 'claim', element: <AccountClaim /> },
        { path: 'inventory', element: <AccountInventory /> },
        { path: 'activity', element: <AccountActivities /> },
        { path: 'settings', element: <AccountSettings /> },
      ],
    },
  ];
  
  return useRoutes(routArrLogin);
}

const MarketBoxes = Loadable(lazy(() => import('../pages/markets/MarketBoxes')));
const MarketBricks = Loadable(lazy(() => import('../pages/markets/MarketBricks')));
const MarketHeroes = Loadable(lazy(() => import('../pages/markets/MarketHeroes')));
const MarketHeroDetail = Loadable(lazy(() => import('../pages/markets/MarketHeroDetail')));
const MarketItems = Loadable(lazy(() => import('../pages/markets/MarketItems')));
const MarketItemsDetail = Loadable(lazy(() => import('../pages/markets/MarketItemsDetail')));
const MarketHouses = Loadable(lazy(() => import('../pages/markets/MarketHouses')));
const MarketHousesDetail = Loadable(lazy(() => import('../pages/markets/MarketHousesDetail')));
const MarketHotels = Loadable(lazy(() => import('../pages/markets/MarketHotels')));
const AccountInventory = Loadable(lazy(() => import('../pages/accounts/AccountInventory')));
const AccountInventoryOther = Loadable(lazy(() => import('../pages/accounts/AccountInventoryOther')));
const AccountSettings = Loadable(lazy(() => import('../pages/accounts/AccountSettings')));
const AccountClaim = Loadable(lazy(() => import('../pages/accounts/AccountClaim')));
const AccountActivities = Loadable(lazy(() => import('../pages/accounts/AccountActivities')));
const MinigameSpinner = Loadable(lazy(() => import('../pages/games/spinner')));
const RankMTB = Loadable(lazy(() => import('../pages/events/RankMTB')));
const RankFusionBomb = Loadable(lazy(() => import('../pages/events/RankFusionBomb')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const TestFunction = Loadable(lazy(() => import('../pages/TestFunction')));
const MarketBoxs = Loadable(lazy(() => import('../pages/stakes/MarketBoxs')));
const BoxFreeMystery = Loadable(lazy(() => import('../pages/stakes/MarketMysteryBoxes')));
const MarketFaucet = Loadable(lazy(() => import('../pages/events/MarketFaucet')));

