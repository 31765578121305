import { Dialog, DialogContent, DialogTitle, Grid, Slide, Typography } from "@mui/material";
import React from "react";
import LoginMetaMaskButton from "./LoginMetaMaskButton";
import LoginWalletConnectButton from "./LoginWalletConnectButton";

const Transition = React.forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);

export default function ConnectWalletBox({ component,isOpen }) {

    const [open, setOpen] = React.useState(isOpen||false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            {component && React.cloneElement(component, { onClick: handleOpen })}
            
            <Dialog
                open={open}
                fullWidth maxWidth='xs'
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                titleStyle={{ textAlign: "center" }}
            >
                <DialogTitle sx={{ mb: 4 }} >
                    <Typography variant="h5" align="center">Connect to a Wallet</Typography>
                </DialogTitle>
                <DialogContent>
                    <div>
                        <Grid container spacing={3}>
                            <Grid item xs={12}> <LoginMetaMaskButton /> </Grid>
                            <Grid item xs={12}> <LoginWalletConnectButton /> </Grid>
                        </Grid>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}
