
import './style.css'
import { useState, useEffect, useCallback } from 'react';
import { Popover, Typography, Box, Stack } from '@mui/material';

import CountUp from 'react-countup';
import HelpIcon from '@mui/icons-material/Help';
import { alpha } from '@mui/material/styles';
import * as wallet from '../utils/cryptoWallet';
import useResponsive from '../hooks/useResponsive';
import useWindowSize from '../hooks/useWindowSize';
import { useDialogs } from '../hooks/useDialogs';
import { PRIZE_POOL_MARKET_ADDRESS } from '../utils/constants';

function Treasure(props) {
    const { noti, onOpen } = useDialogs();
    const isDesktop = useResponsive('up', 'lg');
    const _WIDTH = isDesktop ? 252 : 200;
    const [poolBalance, setPoolBalance] = useState(null);
    
    const getMarketPool = useCallback(async () => {
        const mtbBlance = await wallet.getBalanceAddr(PRIZE_POOL_MARKET_ADDRESS);
        const mtbnum = parseFloat(parseFloat(mtbBlance).toFixed(0))
        setPoolBalance(mtbnum);
    });

    useEffect(() => {
        const timeout = setTimeout(() => { getMarketPool() }, 800);
        return () => clearTimeout(timeout);
    }, [getMarketPool]);

    useEffect(() => {
        const interval = setInterval(() => { getMarketPool() }, 10000);
        return () => clearInterval(interval);
    }, [getMarketPool]);

    return (
        <div {...props}>
            <Box
                sx={{
                    width: _WIDTH,
                    background: 'linear-gradient(to right bottom, #454F5B , #212B36)',
                    borderRadius: isDesktop ? 3 : 2,
                }}
            >
                <Stack direction="row" alignItems="center" justifyContent='space-between' spacing={1.5} sx={isDesktop ? { py: 1, px: 2 }:{ py: .5, px: 1 }}>
                    <Box className={isDesktop ? "treasure_chest" : "treasure_chest_mobile"} />
                    <Stack direction="column" alignItems="left" >
                        <Stack direction="row" alignItems="center" spacing={isDesktop ? 3.3 : 1.1} >
                            <Typography variant={isDesktop ? "subtitle2" : "subtitle2"}>Market Jackpot:</Typography>
                            <HelpIcon className='help_pool' onClick={() => {
                                noti('❤️ MARKET JACKPOT ❤️',
                                    <Stack sx={{mt:2, mx:isDesktop ? 2 : 0}}>
                                        <Typography variant={"subtitle1"}>◦ For every successful transaction on the Marketplace, we will put 1% of $MTB into the Jackpot. </Typography>
                                        <Typography variant={"subtitle1"}>◦ The Jackpot will be rewarded to users for participating in $MTB staking events or playing Mini-games.</Typography>
                                        <Typography variant={"subtitle1"}>◦ We will make detailed announcement in the near future.</Typography>
                                    </Stack>,
                                )
                            } }/>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={0.5}>
                            <img src={`/icons/mtb-token.png`} alt="i" width={20} height={20} loading="lazy" />
                            <Typography variant={isDesktop ? "h4" : "h5"} color='primary.light' >
                                <CountUp end={poolBalance} duration={3} preserveValue separator=','/>
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Box>
        </div>
    );
}

export default function MarketPool(props) {
    const size = useWindowSize();
    const isDesktop = useResponsive('up', 'lg');
    const _WIDTH = isDesktop ? 250 : 200;
    // const { noti, onOpen } = useDialogs();
    return (
        <Treasure
            style={{
                position: 'fixed',
                top: isDesktop ? 20 : 65,
                left: size.width / 2 - _WIDTH/2,
                zIndex: 1200
            }}
        />
    );
}