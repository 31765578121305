import PropTypes from 'prop-types';
import { createContext } from 'react';
import { useMemoryState } from '../utils/memoryState';

// ----------------------------------------------------------------------

const defaultFilter = {
    f5: 0,
    token_id: -1,
    page: 1,
    count: 12,
    sort: 0,
    sort_type: 3,
    box_type: [true, false, false, false],
    totalRow: 0,
    forSale: 2,
}

const initialState = {
    ...defaultFilter,
    onf5:()=>{},
    onChangeTokenId: () => { },
    onChangePage: () => { },
    onChangeCount: () => { },
    onChangeSort: () => { },
    onChangeSortType: () => { },
    onChangeBoxType: () => { },
    onChangeSale: () => { },
    onChangeTotalRow: () => { },
    onReset: () => {}
};

const FilterBoxContext = createContext(initialState);

// ----------------------------------------------------------------------

FilterBoxProvider.propTypes = {
  children: PropTypes.node,
};

function FilterBoxProvider({ children }) {

    const [filterSetting, setFilterSetting] = useMemoryState('filterBoxSetting', {
        f5: initialState.f5,
        token_id: initialState.token_id,
        page: initialState.page,
        count: initialState.count,
        sort: initialState.sort,
        sort_type: initialState.sort_type,
        box_type: initialState.box_type,
        totalRow: initialState.totalRow,
        forSale: initialState.forSale,
    });

    const onReset = () => {
        setFilterSetting({
            ...filterSetting,
            f5: initialState.f5,
            token_id: initialState.token_id,
            // page: initialState.page,
            count: initialState.count,
            sort: initialState.sort,
            // sort_type: initialState.sort_type,
            box_type: initialState.box_type,
            forSale: initialState.forSale,
        });
    };

    const onf5 = (state) => {
        setFilterSetting({
            ...filterSetting,
            f5:state,
        });
    };

    const onChangeTokenId = (state) => {
        setFilterSetting({
            ...filterSetting,
            token_id: state,
            page: 1,
        });
    };

    const onChangeCount = (state) => {
        setFilterSetting({
            ...filterSetting,
            count: state,
        });
    };
    
    const onChangePage = (state) => {
        setFilterSetting({
            ...filterSetting,
            page: state,
        });
    }
        
    const onChangeSort = (state) => {
        setFilterSetting({
            ...filterSetting,
            sort: state,
            
        });
    };
    
    const onChangeSortType = (state) => {
        setFilterSetting({
            ...filterSetting,
            sort_type: state,
            
        });
    };

    const onChangeBoxType = (state) => {
        setFilterSetting({
            ...filterSetting,
            box_type: state,
            page:1
        });
    };

    const onChangeSale = (state) => {
        setFilterSetting({
            ...filterSetting,
            forSale: state,
            page:1
        });
    };

    const onChangeTotalRow = (state) => {
        setFilterSetting({
            ...filterSetting,
            totalRow: state,
        });
    };

    return (
        <FilterBoxContext.Provider
            value={{
                ...filterSetting,
                onf5,
                onChangeTokenId,
                onChangePage,
                onChangeCount,
                onChangeSort,
                onChangeSortType,
                onChangeBoxType,
                onChangeSale,
                onChangeTotalRow,
                onReset
            }}
        >
            {children}
        </FilterBoxContext.Provider>
    );
}

export { FilterBoxProvider, FilterBoxContext }
