/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { linkToHome } from "./utils/link";


class SessionData {
    
    constructor() {
        this.userId = null
        this.jwToken = null
        this.myAddress = null
        this.exchangeRate = 0
        this.provider = null
        this.userName = null
    }

    setSessionData = (userId, userName, jwToken, myAddress, provider) => {
        // console.log(userId,userName, jwToken, myAddress, provider)
        setCookie("token",jwToken)
        setCookie("userid", userId)
        setCookie("username",userName)
        setCookie("myaddress", myAddress)
        localStorage.setItem('provider', provider)
    }

    isLoggedIn(){
        return  this.userId && this.myAddress && this.jwToken
    }

    checkLogIn(){
        if (!this.isLoggedIn()) {
            window.location = linkToHome
        }
    }

    updateAddress(address){
        this.myAddress = address
        setCookie("myaddress",address)
    }

    updateUserName(userName){
        this.userName = userName
        setCookie("username",userName)
    }

    updateProvider(provider){
        this.provider = provider
        localStorage.setItem('provider', provider)
    }

    updateRateUSD(rate){
        this.exchangeRate = rate
        setCookie("mtb2usd",rate)
    }

    getSessionData() {
        
        this.jwToken = getCookie("token");
        this.userId = getCookie("userid");
        this.userName = getCookie("username");
        this.myAddress = getCookie("myaddress");
        this.exchangeRate = getCookie("mtb2usd");
        this.provider = localStorage.getItem("provider")
        // console.log(`jwToken ${  this.jwToken}`);
        // console.log(`userid ${this.userId}`);
        // console.log(`userName ${  this.userName}`);
        // console.log(`provider ${  this.provider}`);
        // console.log(`myAddress ${  this.myAddress}`);
    }

    logOut() {

        this.userId = null;
        this.jwToken = null;
        this.myAddress = null
        this.provider = null
        this.userName = null

        delete_cookie("token", "/");
        delete_cookie("userid", "/");
        delete_cookie("myaddress", "/");
        delete_cookie("username", "/");

        localStorage.removeItem("provider")

        window.location = linkToHome

    }

    // goToLoginPage(){
    //     window.location = linkToLogin

    // }

   
}

export default (new SessionData()); 

function delete_cookie(name, path, domain) {
    // if( get_cookie( name ) ) {
    document.cookie = `${name  }=${ 
        (path) ? `;path=${  path}` : "" 
        }${(domain) ? `;domain=${  domain}` : "" 
        };expires=Thu, 01 Jan 1970 00:00:01 GMT`;
    // }
}

function setCookie(name, value, days) {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = `; expires=${  date.toUTCString()}`;
    }
    document.cookie = `${name  }=${  value || ""  }${expires  }; path=/`;
}

function getCookie(name) {
    const nameEQ = `${name  }=`;
    const ca = document.cookie.split(';');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}