import React from "react";
import _ from "lodash";
import { Grid, FormControlLabel, Checkbox } from '@mui/material';
import {useFilterBomb} from '../../hooks/useFilter';

// ----------------------------------------------------------------------

export default function FilterRarityB() {
  const { rarity, onChangeRarity } = useFilterBomb();
  return (
    <Grid dir="ltr" container spacing={0.5} sx={{ml:1.5}}>
      {['Common', 'Rare', 'Epic', 'Legend', 'Mythic', 'Meta',].map((mode, index) => (
        <Grid key={mode} item xs={6}>
          <FormControlLabel control={
            <Checkbox checked={rarity[index]} name={index}
              onChange={(event) => {
                let ctn = 0;
                _.forEach(rarity, v => { if (v) ctn += 1; });
                // if (ctn <= 1 && !event.target.checked) return
                onChangeRarity({
                  ...rarity,
                  [event.target.name]: event.target.checked
                });
              }}
            />
          } label={mode} />
        </Grid>
      ))}
    </Grid>
  );
}
