import PropTypes from 'prop-types';
import { createContext, useEffect } from 'react';
import { useQuery } from "@apollo/client";

// hooks
import { useMemoryState } from '../utils/memoryState';
import { QUERY_ME } from '../graphql/userApi';

// ----------------------------------------------------------------------

const defaultS = {
    inventoryTab: 0,
    mtbOnchain:0,
    acc: {}
}

const initialState = {
    ...defaultS,
    onChangeInventoryTab: () => { },
    onSetAccount: () => { },
    onSetMTBOnchain: () => { },
    onRefetch: () => { }
};

const AccountContext = createContext(initialState);

// ----------------------------------------------------------------------

AccountProvider.propTypes = {
  children: PropTypes.node,
};

function AccountProvider({ children }) {
    const { loading, error, data, refetch } = useQuery(QUERY_ME);
    
    const [myAccount, setMyAccount] = useMemoryState('MyAccount', {
        inventoryTab: initialState.inventoryTab,
        mtbOnchain:initialState.mtbOnchain,
        acc: initialState.acc,
    });

    useEffect(() => {
        setMyAccount({
            ...myAccount,
            acc: data ? data.me : null
        });
    }, [data]);

    const onRefetch = () => {
        refetch();
    };

    const onChangeInventoryTab = (state) => {
        setMyAccount({
            ...myAccount,
            inventoryTab: state,
        });
    };

    const onSetAccount = (state) => {
        setMyAccount({
            ...myAccount,
            acc: state,
        });
    };

     const onSetMTBOnchain = (state) => {
        setMyAccount({
            ...myAccount,
            mtbOnchain: state,
        });
    };

    return (
        <AccountContext.Provider
            value={{
                ...myAccount,
                onChangeInventoryTab,
                onSetAccount,
                onSetMTBOnchain,
                onRefetch
            }}
        >
            {children}
        </AccountContext.Provider>
    );
}

export { AccountProvider, AccountContext }
